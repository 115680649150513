import { useInView } from 'react-intersection-observer'
import classNames from 'classnames'

type Props = {
  className?: string
  inViewClass?: string
  outViewClass?: string
}

export const AnimateDiv: React.FC<Props> = (props) => {
  const { className, inViewClass = 'opacity-1', outViewClass = 'opacity-0' } = props

  const [ref, inView] = useInView({
    threshold: 0.15,
  })

  return (
    <div
      ref={ref}
      {...props}
      className={classNames(className, 'transition-all duration-1000', { [inViewClass]: inView, [outViewClass]: !inView })}
    />
  )
}
