import React, { useContext } from 'react'
import { ElementProps } from 'journey-ui'
import classNames from 'classnames'
import { AppContext } from '../contexts/AppContext'

interface Props extends ElementProps<'div'> {
  url?: string
}

export const BrandHeader: React.FC<Props> = (props) => {
  const { brand } = useContext(AppContext)
  const url = props.url ?? brand.background ?? '/images/default-brand-background.png'

  return (
    <div
      className={classNames('text-white flex flex-col text-3xl z-10 relative w-full', props.className)}
      style={{ ...props.style, backgroundImage: `url(${url})`, backgroundSize: 'cover', zIndex: 1, backgroundPosition: 'center' }}
    >
      <div className="absolute top-0 left-0 bg-black bg-opacity-60 z-20 w-full h-full" style={{ zIndex: -1 }} />

      <div className="relative z-30 flex justify-center items-center flex-grow">
        {props.children}
      </div>
    </div>
  )
}
