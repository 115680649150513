import { Button, Icon, Img } from 'journey-ui'
import React from 'react'
import { PLATFORM_ICONS } from '../config'
import { GooglePlayButton } from './GooglePlayButton'

export const BrandLinkouts = ({ brand }) => {
  const shouldRender = brand.platforms.length > 0 || (brand.ios_app_url || brand.android_app_url)

  if (!shouldRender) {
    return null
  }

  return (
    <div className="flex justify-center bg-white py-1 items-center space-x-2">
      <GooglePlayButton brand={brand} />
      {/* {brand.platforms.map((platform) => { */}
      {/*  const iconSrc = PLATFORM_ICONS[platform.name] */}

      {/*  return ( */}
      {/*    <Button className="hidden md:flex border-none p-0 w-11 h-11" key={platform.name} href={{ href: platform.url, target: '_blank' }}> */}
      {/*      {iconSrc ? <Icon className="w-10 h-10" src={iconSrc} /> : <span className="text-xs">{platform.name}</span>} */}
      {/*    </Button> */}
      {/*  ) */}
      {/* })} */}
    </div>
  )
}
