import { Button, Icon } from 'journey-ui'
import { ForkKnife, UsersThree } from 'phosphor-react'
import React, { useContext } from 'react'
import { AppContext } from '../contexts/AppContext'
import { BrandHeader } from './BrandHeader'
import { AnimateDiv } from './AnimateDiv'

interface Props {
  title: string
  cover_photo?: string
  description: string
  has_about_page: boolean
}

export const CoverBlock: React.FC<Props> = (props) => {
  const { title, description, has_about_page, cover_photo } = props
  const { brand } = useContext(AppContext)

  return (
    <BrandHeader url={cover_photo} className="flex justify-center items-center flex-col w-full min-h-[16rem] md:min-h-[28rem] relative overflow-hidden">
      <div className="text-center text-white space-y-4 z-30 py-12 px-1">
        <div>
          <AnimateDiv className="text-4xl md:text-7xl md:leading-11 uppercase heading-font">
            {title}
          </AnimateDiv>
          <AnimateDiv className="text-lg md:text-2xl">
            {description}
          </AnimateDiv>
        </div>
        <div className="flex flex-wrap sm:space-y-0 justify-center space-x-2">
          {has_about_page ? (
            <Button className="primary my-1 border-none" href="/about">
              <Icon src={UsersThree} />
              <span>Our Story</span>
            </Button>
          ) : null}
          <Button className="primary my-1 border-none" href="/menu">
            <Icon src={ForkKnife} />
            <span>{brand.show_delivery_option ? 'Place Pickup or Delivery Order' : 'Place Pickup Order'}</span>
          </Button>
        </div>
      </div>
    </BrandHeader>
  )
}
