import { Col, Img, Row } from 'journey-ui'
import React from 'react'
import { awsImageUrl } from '../helpers'

interface Props {
  story: Story
}

export const StoryDisplay: React.FC<Props> = ({ story }) => {
  const images = story.images.slice(0, 3)

  return (
    <div className="flex flex-col justify-center items-center" key={story.hash_id}>
      <div className="max-w-[600px]">
        <h2 className="font-bold my-2 text-3xl">{story.title}</h2>
        <div className="text-center">
          {story.description}
        </div>
      </div>

      <div className="my-8 flex flex-col md:flex-row space-y-2 md:space-y-0 space-x-0 md:space-x-2 items-center justify-center">
        {images.map((image) => (
          <div className="flex justify-center" key={image.hash_id}>
            <Img
              className="rounded-lg w-80 h-64"
              alt={image.name}
              width={320}
              height={256}
              src={awsImageUrl(image.url)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}
