import React from 'react'
import { NextPageContext } from 'next'
import { Col, enhancedFetch, Img, Row } from 'journey-ui'
import { EnhancedFetchError } from 'journey-ui/dist/lib/EnhancedFetchError'
import { useRouter } from 'next/router'
import { AppPage } from '../components/AppPage'
import { CoverBlock } from '../components/CoverBlock'
import { awsImageUrl, writeLog } from '../helpers'
import { BrandLinkouts } from '../components/BrandLinkouts'
import { BrandHeader } from '../components/BrandHeader'
import { StoryDisplay } from '../components/StoryDisplay'

interface Props {
  brand: Brand
  page: Page
}

const CatchAllPage: React.FC<Props> = ({ brand, page }) => {
  const router = useRouter()

  return (
    <AppPage brand={brand} title={page.title} description={page.description} className="full-screen">
      {page.cover_title ? (
        <CoverBlock
          cover_photo={awsImageUrl(page.cover_photo)}
          has_about_page={page.has_about_page}
          title={page.cover_title}
          description={page.cover_description}
        />
      ) : (
        <BrandHeader className="h-40">
          <h2 className="text-3xl z-20 font-bold">
            {page.title}
          </h2>
        </BrandHeader>
      )}

      <div className="flex items-center flex-col w-full bg-white py-3 px-2">
        {router.asPath === '/' ? <BrandLinkouts brand={brand} /> : null}

        <div className="text-center space-y-4">
          {page.stories.map((story) => <StoryDisplay story={story} key={story.hash_id} />)}
        </div>
      </div>
    </AppPage>
  )
}

// noinspection JSUnusedGlobalSymbols (PHPStorm doesn't detect NextJS's pages)
export default CatchAllPage

export async function getServerSideProps(ctx) {
  writeLog(`${ctx.req.headers.host}${ctx.resolvedUrl}`)

  try {
    const pageViewProps = await enhancedFetch<Props>(`/api/brand/${ctx.req.headers.host}/custom-page?slug=${ctx.query.slug?.[0] ?? 'home'}`, {
      ssrContext: ctx,
    })

    if (pageViewProps.page.redirect_to) {
      return {
        redirect: {
          permanent: false,
          destination: pageViewProps.page.redirect_to,
        },
      }
    }

    return {
      props: pageViewProps,
    }
  } catch (error) {
    /* Domain is unlinked. */
    if (error instanceof EnhancedFetchError) {
      if (error.response.status === 460) {
        return {
          redirect: {
            permanent: false,
            destination: '/domain-unlinked',
          },
        }
      } if (error.response.status === 404) {
        return {
          notFound: true,
        }
      }
    }

    throw error
  }
}
