import { Img, isServer, isUsingAppWebview } from 'journey-ui'
import React from 'react'

interface Props {
  brand: Brand
}

export function isIOSDevice() {
  if (isServer()) {
    return false
  }

  return /iphone|ipad|ipod/i.test(navigator.userAgent)
}

export const GooglePlayButton: React.FC<Props> = ({ brand }) => {
  if (!brand.android_app_url) {
    return null
  }

  /* Dont show primarily because iOS app submission will deny if they see a google play button. */
  if (isUsingAppWebview() || isIOSDevice()) {
    return null
  }

  return (
    <>
      <Img
        href={{ href: brand.android_app_url, target: '_blank' }}
        alt="android app"
        size={{ h: 70, w: 150 }}
        src="/images/google-play-badge.png"
      />
    </>
  )
}
